import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

// components
import BlogFooter from "../components/blog/BlogFooter"

// importing images
import hostparty from "../images/blog/profil.png"
import invite from "../images/blog/instagram.png"

const Guides = () => {
  return (
    <PageWrapper>
      <Wrapper>
        <BlogHeader>
          Queue guides:
          <YellowBar />
        </BlogHeader>
        <StyledLink to="/music-guide">
          <Section>
            <BoldParagraph>
              Guide: Spil musik til din næste fest med Queue
            </BoldParagraph>

            <Button>Læs guide</Button>
          </Section>
        </StyledLink>

        <StyledLink to="/invite-guide">
          <Section>
            <BoldParagraph>
              Guide: Inviter til din næste fest med Queue
            </BoldParagraph>
            <Button>Læs guide</Button>
          </Section>
        </StyledLink>

        <StyledLink to="/instagram-guide">
          <Section>
            <BoldParagraph>
              Guide: Tilføj Instagram til din profil
            </BoldParagraph>

            <Button>Læs guide</Button>
          </Section>
        </StyledLink>

        <FooterWrapper>
          <FooterHeader>Gør som alle dine venner.</FooterHeader>
          <Paragraph>
            Følg Queue på Instagram og få det bedste fest content
            <span role="img" aria-label="pointing-right">
              👉
            </span>
          </Paragraph>
          <FooterStyledLink href="https://instagram.com/join_vibe">
            <FooterButton>Følg Queue</FooterButton>
          </FooterStyledLink>
        </FooterWrapper>
      </Wrapper>
    </PageWrapper>
  )
}

export default Guides

// Page Wrapper
const PageWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
`

// Content wrapper
const Wrapper = styled.div`
  max-width: 700px;
  height: auto;

  background-color: inherit;

  margin: 2.5rem 2.5rem 0 2.5rem;
`

// Blog header and subheader
const BlogHeader = styled.h1`
  font-family: "Avenir";
  font-size: 2rem;
  font-weight: 600;

  line-height: 1.3;

  margin-bottom: 0.7rem;

  @media (min-width: 500px) {
    font-size: 2.5rem;
  }

  @media (min-width: 650px) {
    font-size: 2.8rem;
  }
`

const Yellow = styled.span`
  color: #fdde68;
`

// -------------

// Section styles
const Section = styled.section`
  max-width: inherit;
  height: auto;

  margin-top: 2.5rem;

  display: flex;
  flex-direction: column;
  background-color: #fdde68;
  border-radius: 20px;
  padding: 20px 40px 30px 40px;
  -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.5);
`

// Sticking header and bar together
const HeaderContainer = styled.div`
  height: auto;

  display: flex;
  flex-direction: column;

  justify-content: space-between;

  margin-bottom: 1rem;
`

const Header = styled.h1`
  font-family: "Avenir";
  font-size: 1rem;
  font-weight: 700;

  line-height: 1;

  margin-bottom: 0.6rem;

  @media (min-width: 500px) {
    font-size: 1.2rem;
  }

  @media (min-width: 650px) {
    font-size: 1.5rem;
  }
`

const YellowBar = styled.div`
  width: 80px;
  height: 15px;

  background-color: #fdde68;

  @media (min-width: 500px) {
    width: 100px;
  }

  @media (min-width: 650px) {
    width: 115px;
  }
`

// Link to next guide
const More = styled.div`
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: column;
`

const BoldParagraph = styled.p`
  font-family: "Avenir";
  font-size: 1.2rem;

  margin-bottom: 1rem;

  @media (min-width: 500px) {
    font-size: 1.4rem;
  }

  @media (min-width: 650px) {
    font-size: 1.6rem;
  }
`

const Button = styled.div`
  width: 130px;
  height: 30px;
  line-height: 0rem;
  margin-bottom: 0rem;

  background-color: #fff;

  -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.6);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.6);

  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 500px) {
    width: 180px;
    height: 35px;
  }

  @media (min-width: 650px) {
    width: 230px;
    height: 40px;
  }
`

const StyledLink = styled(props => <Link {...props} />)`
  font-family: "Avenir";
  font-size: 1rem;
  text-decoration: none;

  color: black;

  @media (min-width: 650px) {
    font-size: 1.2rem;
  }
`

const FooterWrapper = styled.div`
  width: 100%;
  height: auto;

  margin-top: 7rem;
  margin-bottom: 130px;

  display: flex;
  flex-direction: column;
`

const FooterHeader = styled.h1`
  font-family: "Avenir";
  font-size: 1.7rem;
  font-weight: 700;

  line-height: 1;

  margin-bottom: 0.6rem;
  @media (min-width: 500px) {
    font-size: 2rem;
  }

  @media (min-width: 650px) {
    font-size: 2.2rem;
  }
`

const Paragraph = styled.p`
  font-family: "Avenir";
  font-size: 1.2rem;
  font-style: normal;
  font-weight: lighter;

  line-height: 1.3;

  margin-bottom: 1.5rem;

  @media (min-width: 500px) {
    font-size: 1.4rem;
  }

  @media (min-width: 650px) {
    font-size: 1.6rem;
  }
`

const FooterButton = styled.div`
  width: 250px;
  height: 50px;

  background-color: #fdde68;

  -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.75);

  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;
`

const FooterStyledLink = styled(props => <a {...props} />)`
  font-family: "Avenir";
  font-size: 1.4rem;
  text-decoration: none;

  color: black;
`
